<template>
    <div>
        <v-card color="white" style="" v-for="item in savingsLegalBindings"
            :key="item.id"
            :to="item.get_link" 
        >
            <v-card-text style="font-size: 12px; cursor: pointer">
                <h3 class="blue--text">{{item.name}}</h3> 
                <span><b>Articles:</b>  {{item.articles}}</span>
                <p class="pa-0 ma-0" style="font-size: 12px"> {{item.description| truncate(100, '...')}}</p>
            </v-card-text>
        <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                savingsLegalBindings: [],
            }
        },

        methods:{
            getLegalBindings(){
                axios
                    .get('/api/v1/home/get/legal_bindings/Monitor/info/')
                    .then(response => {
                        this.savingsLegalBindings = response.data  // get the data and fill into campaigns
                        console.log(this.savingsLegalBindings)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getLegalBindings();
        },
    }
</script>
